import React from "react";

import Layout from "../../components/layout";
import { Button, Seo } from "../../components";
import WithService from "../../service/withService";
import { newlineBreakerFromString } from "../../utils/utils";

const FCRAList = ({ loading, response, error }) => {
  return (
    <div className="fcra">
      <p>{newlineBreakerFromString(response?.Fcra?.Description)}</p>
      <div className="fcra__list">
        {response?.FcraFiles?.length > 0 &&
          response?.FcraFiles?.map(item => {
            return (
              <div className="fcra__list-item">
                <div className="fcra__list-item-title">{item.Name}</div>
                <a
                  href={item.Pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  <Button
                    title="Read Now"
                    theme="primary"
                    type="button"
                    variant="solid"
                  />
                </a>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const FCRA = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="FCRA" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">FCRA</h3>
              <WithService endpoint="GetFcra">
                <FCRAList />
              </WithService>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FCRA;
